<template>
  <!-- 气泡提示 -->
  <!-- 不同气泡提示不同 -->
  <div
    class="bubble-tip"
    :class="[`bubble-tip_${cartTipStatus}`, searchFeedbackVisibility ? 'bubble-tip-feedback' : '']"
  >
    <template v-if="cartTipStatus === 'freeshipping'">
      <free-tip />
    </template>
    <template v-if="cartTipStatus === 'gift'">
      <gift-tip />
    </template>
    <!-- 处理(针对劵类型的免邮，返回的是富文本，所以兼容这种情况) -->
    <p v-html="bubbleTipText"></p>
  </div>
</template>

<script>
import freeTip from 'public/src/pages/product_list_v2/components/CartBag/freeTip.vue'
import giftTip from 'public/src/pages/product_list_v2/components/CartBag/giftTip.vue'

export default {
  components: {
    freeTip,
    giftTip
  },
  props: {
    cartTipStatus: {
      type: String,
      default: ''
    },
    bubbleTipText: {
      type: String,
      default: ''
    },
    searchFeedbackVisibility: {
      type: Boolean,
      default: false
    },
  }
}
</script>

<style lang="less">
/* 利诱点样式 */
@colorFree: #1B8157; // 免邮
@colorSave: #FA6338; // 优惠金额
@colorGift: #FA6338;// 赠品

// 利诱气泡样式
.show-scroll-top .bubble-tip{
  transform: translateY(-1.436rem);
}

.show-scroll-top .bubble-tip-feedback {
  transform: translateY(-0.9067rem);
  bottom: 3.2533rem;
}
.bubble-tip {
  width: 0;
  opacity: 1;
  visibility: hidden;
  position: fixed;
  .right(0.51rem);
  bottom: 2.0267rem;
  z-index: @zindex-list-wrapper;
  overflow: hidden;
  max-width: 9.4rem;
  height: 0.96rem;
  transform: translate3d(0, 0, 0);
  font-size: 12px;
  border-radius: 0.96rem;
  display: flex;
  align-items: center;
  padding-left: 0.213rem;
  padding-right: 0.96rem;
  >p {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    color: #FFF;
    font-family: SF UI Text, Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.bubble-tip-feedback {
  bottom: 3.2533rem;
}
.bubble-tip_freeshipping {
  background-color: @colorFree;
  gap: 3px;
  >p {
    max-width: calc(100% - 18px);
  }
}
.bubble-tip_save {
  background-color: @colorSave;
}
.bubble-tip_gift {
  background-color: @colorGift;
  gap: 3px;
  >p {
    max-width: calc(100% - 18px);
  }
}

</style>
