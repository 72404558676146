<template>
  <svg
    preserveAspectRatio="xMinYMin meet"
    viewBox="0 0 140 140"
    class="cart-bag__svg"
  >
    <g>
      <circle
        r="64.5"
        cx="50%"
        cy="50%"
        :stroke="stroke || '#231f20'"
        stroke-width="8"
        fill-opacity="1"
        fill="white"
        :class="['cart-bag__circle', animateCartBagStroke ? 'cart-bag__circle-actived' : '']"
      />
    </g>
  </svg>
</template>

<script>
//  0到1 加载圈SVG
export default {
  props: {
    stroke: {
      type: [String, Boolean],
      default: ''
    },
    animateCartBagStroke: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="less">
@keyframes cart-bag-stroke {
  to {
    stroke-dashoffset: 0;
  }
}
.cart-bag__svg {
  position: absolute;
  left: 0;
  top: 0;
  transform: rotate(-90deg);
  width: 0.96rem;
  height: 0.96rem;
}
.cart-bag__circle {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
}
.cart-bag__circle-actived {
  animation: .3s cubic-bezier(0.4, 0.0, 1, 1) .1s forwards cart-bag-stroke;
}
</style>
